import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';
import Header from "../components/Header/Header"
import TaC from "../components/TaC/TaC"
import Footer  from "../components/Footer/Footer"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

const TaCPage = () => {
	const { myBackground } = useStaticQuery(
		graphql`
			query {
				myBackground: file(relativePath: { eq: "background.jpg" }) {
				childImageSharp {
					gatsbyImageData(
					quality: 90, 
					webpOptions: {quality: 100}, 
					width: 2000
					)
				}
				}
			}
		`
	)

	const pluginImage = getImage(myBackground);

	return (
		<BgImage image={pluginImage}>
			<Header />
			<TaC />
			<Footer />
		</BgImage>
	)
}
export default TaCPage