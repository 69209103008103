import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const TaC = () => {
    return( 
        <div id="tac">
            <Container>
                <Row className="bgPanel">
                    <Col>
                        <h1> TERMS AND CONDITIONS </h1>
                        <hr/>
                            <div className="tacStyle">
                                <b>Definitions</b>
                                <p/>
                                Virtual items: Team Fortress 2 in-game items
                                <br/>
                                Donation Tracker: system facilitation of monetary donations to be viewed <a className="styledLink" href="https://streamlabscharity.com/teams/@a-drop-of-delight-tf2-charity-fundraiser/a-drop-of-delight-tf2-fundraiser?member=323911937144524800" target="_blank" rel="noopener noreferrer"> here </a>
                                <br/>
                                The Charity: nonprofit organization Just a Drop, providing for communities with lacking access to necessary sanitary water
                                <p/>
                                <b>Transparency</b>
                                <p/>
                                All financial donations will directly profit Just a Drop in their aim to provide clean water to communities in need. The associated email of the charity is <u>contact@adropofdelight.org</u>. Donations via the donation tracker will be confirmed through a receipt from StreamLabs. 
                                <p/>
                                Provided name, amount of donation and time of donation will be published on our website adropofdelight.org. Those who wish to remain anonymous will have the option of selecting the nickname of “anonymous” upon donating. Changes to nicknames as displayed on our website can be made by contacting contact@adropofdelight.org with your donation receipt to be amended.
                                <p/>
                                <b>Donations</b>
                                <p/>
                                Monetary donations are 
                                <br/>
                                <ul>
                                    <li> Tax-deductible </li>
                                    <li> Non-refundable </li>
                                    <li> Can be made anonymously </li>
                                </ul>
                                <br/>
                                Monetary donations made through Streamlabs will be eligible for sweepstakes and website/stream recognition. No entity at A Drop of Delight will have any access to your donation for any reason; all donations are directly made towards Just a Drop. 
                                <p/>
                                <b>Giveaway</b>
                                <p/>
                                There will be a free TF2 item giveaway, with Scrap.tf as a hosting platform. All rules, terms, regulation, and policies of Scrap.tf will be applied and enforced. 
                                <p/>
                                <b>Privacy Policy</b>
                                <p/>
                                A Drop of Delight is committed to maintaining the confidentiality, security, accuracy and privacy of any and all individual personal information collected through the visitation and use of our online services. Our privacy policy has been written and designed to ensure affiliates of our commitment to our obligation to not only meet, but exceed existing privacy standards. 
                                <p/>
                                <b>Information Collection and Use</b>
                                <p/>
                                A Drop of Delight will only collect personal information that you knowingly and willingly provide through completed forms and emails. Personal information will be used for the sole purposes for which it was requested to be used. 
                                <p/>
                                Personal information that may be used includes: 
                                <ul>
                                    <li> Voluntarily provided information through donations, forms and contact information </li>
                                    <li> Full name </li>
                                    <li> Email </li>
                                </ul>
                                <p/>
                                Information collected by A Drop of Delight may be used to assist in website operation to ensure proper and efficient delivery of services for our users. They may further be used as recognition through our donation tracker, specifically in our top 100 donations leaderboard. 
                                <p/>
                                <b>GDPR rights</b>
                                <p/>
                                <b>Children Under Age 13</b><br/>
                                A Drop of Delight will not knowingly collect personal information from children under the age of thirteen without verifiable parental consent. If it is determined that such information has been collected inadvertently, we shall immediately take the necessary actions to ensure such information is determined and deleted from our system database. Any person under the age of thirteen must seek and obtain parental or legal guardian consent and permission to use this website. 
                                <p/>
                                <b>Changes to Privacy Policy Agreement</b><br/>
                                A Drop of Delight reserves the right to update and/or change any of the terms of our privacy policy, of which our website will have posted notification for our user’s knowledge. If there are any drastic changes towards this privacy agreement regarding personal identifiable information, user(s) will be promptly notified by email and given the option as to whether we are allowed to use their information in alternate manners. 
                                <p/>
                                <b>Consent</b><br/>
                                By using our website, you hereby consent to and agree to the terms of our Privacy Policy. 
                                <p/>
                            </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TaC;